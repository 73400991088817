 <!-- 居家养老 -->
<template>
  <div> 
    <van-cell title="绑定手环" is-link @click="goBinding" />
    <!-- <van-cell title="APP下载" is-link url="https://api.xcloudtech.com/g?Date=1589782300&UID=d980305bacbd96852d272585&Sign=acbd780722aa6743b8067153db8b1d4f&Ext=" /> -->
    <van-cell title="APP下载" is-link url="https://fangzoudiu.xcloudtech.com/xiao_jian/" />
     
  </div>
</template> 

<script>  

export default {
  name: "",
  components: {},
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 100,
    },
  },
  data() {
    return {
      formData: {
        deviceId: "",
        sex: "",
        radio: "",
        devicePhone: "",
        userPhone: "",
        contactName: "",
        contactPhone: "",
        address: "",
        remarks: "",
      },
      list: [],
      loading: false,
      finished: false,
    };
  },
  // 计算属性
  computed: {},

  // watch
  watch: {},

  // 创建完成（可以访问当前this实例）
  created() { 
  },
 
  mounted() {    
  },

  // 方法集合
  methods: { 
    goBinding(){
       window.location.href = '/binding'
    //  this.$router.push({path: '/binding'})
    },
  
  },
};
</script>

<style lang='scss'>
</style> 